<template>
	<div v-if="version == 2" class="version2 flex direction_column">
		<div class="active">登录</div>
		<div class="js-login">
			<div class="js-login__form">
				<p class="js-login__phone flex align_items_c">
					<img
						src="https://zy.metaera.media/assets/images/my/email_tu.png"
						class="pointer"
					/>
					<input
						type="text"
						placeholder="请输入邮箱"
						v-model="loginForm.email"
					/>
				</p>
				<p class="js-login__psd margin_top_20">
					<img
						src="https://zy.metaera.media/assets/images/my/pass_tu.png"
						class="pointer"
					/>
					<input
						placeholder="请输入密码"
						type="password"
						v-model="loginForm.password"
					/>
				</p>
				<p class="js-login__info">
					<span
						class="js-login__changePhone pointer"
						@click="$parent.$parent.showStatus = 3"
						>验证码登录</span
					>
					<span
						class="js-login__forget pointer"
						@click="$parent.$parent.showStatus = 4"
						>忘记密码？</span
					>
				</p>
				<button
					class="loginBtn flex align_items_c justify_content_c pointer"
					:disabled="loginForm.email == '' || loginForm.password == ''"
					@click="handleLogin"
				>
					登 录
				</button>
				<div class="js-login_reigister">
					新用户？<i @click="$parent.$parent.showStatus = 2">创建账号</i>
				</div>
			</div>
			<div class="js-thirdParty margin_top_20">
				<div class="js-thirdParty__head flex">
					<span class="line"></span>
					<span class="text">其他第三方登录</span>
					<span class="line"></span>
				</div>

				<div class="js-thirdParty__list">
					<div
						id="g-signin2"
						class="flex align_items_c justify_content_c login_third login_google pointer"
						@click="loginByGoogle()"
					>
						<img
							src="https://zy.metaera.media/assets/images/my/google_tu.png"
						/>
						<p class="letter-space">Google快速登录</p>
					</div>
				</div>
			</div>
			<div class="return_btn flex justify_content_c margin_top_20">
				<p @click="toHome" class="pointer"
					>返回首页<img
						src="https://zy.metaera.media/assets/images/my/arrow_tu.svg"
				/></p>
			</div>
		</div>
	</div>
</template>

<script>
import store from "@/store";
import login__areaCode from "./login__areaCode.vue";
import "./login_common.css";
import wxlogin from "vue-wxlogin";
import { gapi } from "gapi-script";

export default {
	name: "",
	props: ["version"],
	components: { login__areaCode },
	data() {
		return {
			loginForm: {
				username: "",
				password: "",
				type: "1",
				phone_code: "86",
				email: "", // 2.0版本升级邮箱
			},
			passType: "password",

			showtimezone: false,
		};
	},
	methods: {
		toHome() {
			this.$parent.$parent.dialogVisible = false;
		},
		getphone_code(val) {
			this.loginForm.phone_code = val;
			this.showtimezone = false;
		},
		handleLogin() {
			console.log(this.$parent.$parent.showStatus);
			var loginInfo = {};
			if (this.$parent.$parent.showStatus == 1) {
				loginInfo = {
					email: this.loginForm.email,
					password: this.loginForm.password,
					type: "4",
					username: "",
				};
				// 邮箱密码登录
			}
			store
				.dispatch("user/login", loginInfo)
				.then(() => {
					this.$parent.$parent.dialogVisible = false;
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},

		loginByGoogle() {
			gapi.load("auth2", () => {
				const auth2 = gapi.auth2.init({
					client_id:
						"281027632976-likjmer31hbpfe3jrltufvg3obq9kak5.apps.googleusercontent.com",
				});

				auth2.attachClickHandler(
					document.getElementById("g-signin2"),
					{},
					(googleUser) => {
						const profile = googleUser.getBasicProfile();
						let loginInfo = {
							openid: profile.getId(),
							email: profile.getEmail(),
							avatar_img: profile.getImageUrl(),
							nickname: profile.getName(),
						};
						console.log("ID: " + profile.getId());
						console.log("Name: " + profile.getName());
						console.log("Image URL: " + profile.getImageUrl());
						console.log("Email: " + profile.getEmail());
						store
							.dispatch("user/googleLogin", loginInfo)
							.then(() => {
								this.$parent.$parent.dialogVisible = false;
							})
							.catch((error) => {
								this.$message.error(error.message);
							});
					},
					(error) => {
						if (error.error === "popup_closed_by_user") {
							console.log(
								"The user closed the popup without completing the sign-in."
							);
						} else {
							console.log(JSON.stringify(error, undefined, 2));
						}
					}
				);
			});
		},
	},
};
</script>

<style scoped>
/* 登录注册 */
.loginTab {
	margin-top: 14px;
}

.loginTab div {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 40px;
	font-weight: 600;
	line-height: 40px;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;
}

.loginTab div:nth-child(2) {
	margin-left: 47px;
}

.active {
	position: relative;
	font-weight: bold;
}

.loginMain {
	margin-top: 50px;
	width: 344px;
}

.login_form {
	width: 100%;
	height: 61px;
	border-bottom: 1px solid #e1e1e1;
}

.numberarea {
	font-size: 17px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 400;
	color: #666666;
	width: 78px;
}

.login_form input {
	width: 60%;
	height: 90%;
	border: none;
	outline: none;
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000;
}

.login_form img {
	width: 14px;
}

.login_bt {
	margin-top: 15px;
}

.login_bt div {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #0056ff;
}

.loginBtn {
	width: 335px;
	height: 50px;
	background: linear-gradient(
		45deg,
		#9945ff,
		#8752f3,
		#5497d5,
		#43b4ca,
		#28e0b9,
		#19fb9b
	);
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	margin-top: 23px;
	font-size: 16px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #ffffff;
	border: none;
	outline: none;
}

.loginBtn[disabled] {
	background: #8cb3ff;
	color: #ffffff;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0px;
}

.otherLogin {
	margin-top: 37px;
}

.otherLogin .el-divider__text {
	font-size: 14px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #999999;
}

.wxlogin {
	width: 40px;
	margin-top: 28px;
}

@import url("../../assets/log.css");
</style>
